import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TasksIcon from "@material-ui/icons/CheckCircle";
import CalendarIcon from "@material-ui/icons/CalendarToday";

import Headroom from "react-headroom";

import Tasks from "./Tasks";
import Calendar from "./Calendar";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    // maxWidth: 500,
  },
});

function IconTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <>
    <Headroom
        onPin={() => console.log("pinned")}
        onUnpin={() => console.log("unpinned")}
        // wrapperStyle={{ marginBottom: rhythm(1) }}
        className={classes.headroom}
        style={{
          background: "#546e7a",
          boxShadow: "1px 1px 1px rgba(0,0,0,0.25)"
        }}
      >
    <Paper square className={classes.root}>
      <Tabs
        style={{height:'56px'}}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab icon={<TasksIcon />} style={{height:'56px'}}/>
        <Tab icon={<CalendarIcon/>} style={{height:'56px'}}/>
      </Tabs>
   
    </Paper>
      </Headroom>

    {value === 0 && <Tasks/>}
    {value === 1 && <Calendar/>}
    </>

  );
}

export default IconTabs;