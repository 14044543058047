import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

import Card from "@material-ui/core/Card";
import * as dateFns from "date-fns";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import Slide from "@material-ui/core/Slide";
import Grow from "@material-ui/core/Grow";
import Zoom from "@material-ui/core/Zoom";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Fab from "@material-ui/core/Fab";
import CancelIcon from "@material-ui/icons/IndeterminateCheckBox";

const styles = theme => ({
  root: {
    flex: 1,
    padding: theme.spacing.unit,
    margin: theme.spacing.unit
    // flexGrow: 1
  },

  fab: {
    margin: theme.spacing.unit,
    position: "fixed",
    // bottom: 64,
    right: theme.spacing.unit,
    zIndex: theme.zIndex.drawer + 2,
    bottom: 32
  }
});

function Transition(props) {
  //return <Slide direction="up" {...props} />;
  // return <Grow in={true} {...props} />;
  // return <Zoom in={true} {...props} style={{ transitionDelay:'250ms' }}/>;
  return <Zoom in={true} {...props} />;
}

const AddingReminder = props => {
  const [value, setValue] = useState("");
  const handleSubmit = e => {
    e.preventDefault();
    props.onItemAdd(value);
    setValue("");
  };

  return <div />;
};

function DashNoEvent(props) {
  const { classes } = props;
  // const [open, setOpen] = React.useState(props.open);

  return (
    <Grid>
      <Card className={classes.root}>
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="stretch"
        >
          <Grid item xs>
            <Avatar
              aria-label="logo"
              alt="Fern Events logo"
              src="./images/fe_icon.png"
              className={classes.avatar}
              
            />
          </Grid>
          <Grid item xs>
            <Typography variant={"h5"}>
              {" "}
              Weclome to Fernanda Events.{" "}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography>
              {" "}
              Lets get started by creating your first event!{" "}
            </Typography>
          </Grid>
          <Grid item xs>
            <Link to="/addEvent">
              <Button>Add an event</Button>
              <Button
                size="small"
                color="secondary"
                aria-label="Save"
                type="submit"
              >
                <AddCircleIcon />
              </Button>
            </Link>
          </Grid>
          <Link to="/addEvent">
          <Fab color="secondary" className={classes.fab}>
          
            <AddIcon />
           
          </Fab>
          </Link>
        </Grid>
      </Card>
    </Grid>
  );
}

export default withStyles(styles)(DashNoEvent);
