import React, { Component } from 'react';
// import Delay from 'react-delay';
import Loading from '../components/Loading'
import { auth } from '../firebase';

export default WrappedComponent => {
  class WithAuthentication extends Component {
    state = {
      providerData: [],
      userId: []
    };
    componentDidMount() {
      auth.getAuth().onAuthStateChanged(user => {
        if (user) {
          // console.log(user)
          this.setState({ providerData: user.providerData, userId: user.uid});
        } else {
          console.info('Must be authenticated');
          this.props.history.push('/');
        }
      });
    }

    componentWillUnmount() {
      

    }


    render() {
      return this.state.providerData.length > 0 ? (
        <WrappedComponent
          {...this.props}
          providerData={this.state.providerData}
          userId={this.state.userId}
        />
      ) : (
        // <Delay wait={250}>
         <Loading/>
          // <p>Loading...</p>
        // </Delay>
      );
    }
  }

  return WithAuthentication;
};