import React, { createContext, useState, useEffect, useContext } from "react";
import firebase from "../firebase/firebase";
import UserDataProvider from "./UserData";

const db = firebase.firestore();
const context = createContext(null);

export default function EventDataProvider(props) {
  const user = useContext(UserDataProvider.context);

  const [currentEvent, setCurrentEvent] = useState(null);

  const [tasks, setTasks] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [images, setImages] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  //once off enevt run on first load:
  //still feels a bit likea  hackk... go through thsi again when there is a chance

  useEffect(() => {
    if (!user.isLoading) {
      setIsLoading(false);
    }
  }, [user.isLoading]);

  useEffect(() => {
    if (!user.isUserEventsLoading && user.userEvents.length>0) {
      let current = user.userEvents.find(function(doc) {
        return doc.id === user.userData.evnt_c;
      });
      setCurrentEvent(current);
    }
  }, [user]);

  useEffect(() => {
    if (!user.isLoading && user.userData.evnt_c !== "") {
      const docRef = db
        .collection("events")
        .doc(user.userData.evnt_c)
        .collection("tsks");
      docRef.onSnapshot(
        // {
        //   includeMetadataChanges: true
        // },
        function(querySnapshot) {
          let tasks = [];
          querySnapshot.forEach(function(doc) {
            tasks.push({ id: doc.id, ...doc.data() });
          });
          setTasks(tasks);
          // console.log(`eventContext > Tasks :| -> ${tasks}`);
        },
        function(error) {
          //... handle error
        }
      );
    }
  // }, [isLoading]);
  }, [currentEvent]);

  useEffect(() => {
    if (!user.isLoading && user.userData.evnt_c !== "") {
      const docRef = db
        .collection("events")
        .doc(user.userData.evnt_c)
        .collection("rems");
      docRef.onSnapshot(
        // {
        //   includeMetadataChanges: true
        // },
        function(querySnapshot) {
          let reminders = [];
          querySnapshot.forEach(function(doc) {
            reminders.push({ id: doc.id, ...doc.data() });
          });
          setReminders(reminders);
        },
        function(error) {
          //...handle error
        }
      );
    }
  }, [currentEvent]);

  useEffect(() => {
    if (!user.isLoading && user.userData.evnt_c !== "") {
      const docRef = db
        .collection("events")
        .doc(user.userData.evnt_c)
        .collection("imgs");
      docRef.onSnapshot(
        // {
        //   includeMetadataChanges: true
        // },
        function(querySnapshot) {
          let images = [];
          querySnapshot.forEach(function(doc) {
            images.push({ id: doc.id, ...doc.data() });
          });
          setImages(images);
        },
        function(error) {
          //...handle error
        }
      );
    }
  }, [currentEvent]);

  let eventData = { currentEvent, tasks, reminders, images, isLoading };

  return (
    <context.Provider value={eventData}>{props.children}</context.Provider>
  );
}

EventDataProvider.context = context;
