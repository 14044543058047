import React, {useState} from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputBase from "@material-ui/core/InputBase";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import FavIcon from "@material-ui/icons/Favorite";
import SearchIcon from "@material-ui/icons/Search";
import FavBorderIcon from "@material-ui/icons/FavoriteBorder";
import ExploreIcon from "@material-ui/icons/Explore";
import ExploreOffIcon from "@material-ui/icons/ExploreOff";
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
  root: {
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },

  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },

  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },

  searchIcon: {
    width: theme.spacing.unit*5,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  inputRoot: {
    color: "inherit",
    width: "100%"
  },

  favouritesButton: {
    // paddingRight: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }, 

  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit*5,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: 100,
      "&:focus": {
        width: 200
      }
    }
  }
});



function SearchAppBar(props) {
  const { classes } = props;

  const [viewExplore, setViewExplore] = useState(false)

//   const handleChange = () => {

//   }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer">
            <FavIcon />
          </IconButton> */}

       {/* <FormControlLabel
           className={classes.favouritesButton}
            control={
              <Checkbox
                icon={<FavBorderIcon fontSize="small"/>}
                checkedIcon={<FavIcon />}
                onChange= {()=>setViewExplore(!viewExplore)}
                value= 'favourites'
              />
            }
          />  */}

    <FormControlLabel
           className={classes.favouritesButton}
            control={
              <Checkbox
                icon={<ExploreOffIcon fontSize="small" />}
                checkedIcon={<ExploreIcon  />}
                onChange= {()=>setViewExplore(!viewExplore)}
                value='explore'
              />
            }
          />

          <div className={classes.grow} />
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

SearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchAppBar);
