import React, { Component } from "react";
import { Link } from "react-router-dom";

import { useInput } from '../hooks/hooks';

import SocialButtonList from "./SocialButtonList";
import { auth } from "../firebase";

import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const buttonList = {
  // github: {
  //   visible: true,
  //   provider: () => {
  //     const provider = auth.githubOAuth();
  //     provider.addScope('user');
  //     return provider;
  //   }
  // },
  // twitter: {
  //   visible: true,
  //   provider: () => auth.twitterOAuth()
  // },
  // facebook: {
  //   visible: true,
  //   provider: () => auth.facebookOAuth()
  // },
  // anonymous: {
  //   visible: true,
  //   provider: () => auth.anonOAuth()
  // },
  google: {
    visible: true,
    provider: () => auth.googleOAuth()
  }
};



const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

function SignIn(props) {
  
  const { classes } = props;
  const { value:valueEmail, bind:bindEmail, reset:resetEmail } = useInput('');
  const { value:valuePass, bind:bindPass, reset:resetPass } = useInput('');

  React.useEffect(() => {
    auth.getAuth().onAuthStateChanged(user => {
      if (user) {
        props.history.push("/dashboard");
      }
    });
  });

  const authHandler = authData => {
    if (authData) {
      props.history.push("/dashboard");
    } else {
      console.error("Error authenticating");
    }
  };

  const handleSubmit = e => {
    auth
      .getAuth()
      .signInWithEmailAndPassword(valueEmail, valuePass)
      .then(authHandler)
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === "auth/wrong-password") {
          alert("Wrong password.");
        } else {
          alert(errorMessage);
        }
        console.log(error);
      });
    e.preventDefault();
  };

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input {...bindEmail} id="email" name="email" autoComplete="email" autoFocus />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
            {...bindPass}
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign in
          </Button>

          <SocialButtonList buttonList={buttonList} auth={auth.getAuth} />

        </form>
      </Paper>
    </main>
  );
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignIn);
