import React from "react";

import { withStyles } from "@material-ui/core/styles";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Checkbox from "@material-ui/core/Checkbox";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import DragIcon from "@material-ui/icons/DragIndicator";
import IconButton from "@material-ui/core/IconButton";


const styles = theme => ({
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },

  likeButton: {
    color: theme.palette.primary.contrastText
  }, 

  dragButton: {
    color: theme.palette.primary.contrastText,
    padding: '0px',
    margins: '0px'
  },
});

const Mood = ({
  index,
  onClick,
  photo,
  margin,
  direction,
  top,
  left,
  ...props
}) => {
  const { classes } = props;

  const imgStyle = {

    transition:
      "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
  };

  const selectedImgStyle = {
    transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
    transition:
      "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
  };

  const cont = {
    backgroundColor: "#29434e",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative"
  };

  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }

  return (
    <div
      style={{ margin, height: photo.height, width: photo.width, ...cont }}
      className={!photo.selected ? "not-selected" : ""}
    >
    {console.log(photo)}
      {/* <img
        style={
          photo.selected
            ? { ...imgStyle, ...selectedImgStyle }
            : { ...imgStyle }
        }
        {...photo}
        onClick={e => onClick(e, { index, photo })}
      alt={'alternate'}/> */}

      <GridListTile key={`${photo.author}_${photo.title}`} style={{ listStyleType: "none" }}>
        <img {...photo} alt={`${index}`} />
        <GridListTileBar
          title={ ""
            //   <>
            // <IconButton className={classes.dragButton} >
            //   < DragIcon />
            // </IconButton> 
            // </>
          }

          titlePosition="top"
          actionIcon={
            <Checkbox
              className={classes.likeButton}
              icon={<FavoriteBorderIcon fontSize="small" />}
              checkedIcon={<FavoriteIcon />}
              onChange={() => console.log("liked")}
              value="explore"
            />
          }
          actionPosition="right"
          className={classes.titleBar}
        />
      </GridListTile>
    </div>
  );
};

export default withStyles(styles)(Mood);
