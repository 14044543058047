import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = theme => ({
  progressCircle: {
    margin: theme.spacing.unit * 2,
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-100px"
  },
  progressCircleInComponent: {
    margin: theme.spacing.unit * 2,
  },
  progressLine: {
    margin: theme.spacing.unit * 2,
    position: "fixed",
    top:"-5px"
  },
  progressLineInComponent: {
  }

});

function CircularIndeterminate(props) {
  const { classes } = props;
  return (
    <div>
      {props.circle ? (
        props.inComponent ? (
          <CircularProgress
            className={classes.progressCircleInComponent}
            size={25}
            color="primary"
          />
        ) : (
          <CircularProgress
            className={classes.progressCircle}
            size={50}
            color="primary"
          />
        )
      ) : props.inComponent ? (
        <LinearProgress
          className={classes.progressLine}
          size={50}
          color="primary"
        />
      ) : (
        <LinearProgress
          className={classes.progressLineInComponent}
          size={50}
          color="primary"
        />
      )}
    </div>
  );
}

CircularIndeterminate.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CircularIndeterminate);
