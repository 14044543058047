import React, { useEffect, useState, createContext, useContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

//m-ui utils
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';

//'views'
import Dashboard from "./Dashboard";
import TasksReminders from "./TasksReminders";
//import Tasks from "./Tasks";
import MoodBoard from "./MoodBoard";
// import Calendar from "./Calendar";
import HomeScreen from "./HomeScreen";
import About from "./About";
import Settings from "./Settings";

import EventBuilder from "../components/EventBuilder";

//components
import WithAuth from "./WithAuth";
import Login from "../components/Login";
import AppBarDrawer from "../components/AppBarDrawer";
import BottomBar from "../components/BottomBar";
import GenSnack from "../components/GenSnack";

//context providers
import EventDataProvider from "../contextProviders/EventData";
import UserDataProvider from "../contextProviders/UserData";


//hooks
import { useLogin } from "../hooks/hooks";
import { useNotify } from "../hooks/hooks";

export const AppContext = createContext();

const setApp = {
  appName: "Fernanda | Events",
  // appName : "Proteas & Peacocks",
  appTheme: createMuiTheme({
    palette: {
      primary: {
        light: "#819ca9",
        main: "#546e7a",
        dark: "#29434e",
        contrastText: "#ffffff"
      },
      secondary: {
        light: "#fff59d",
        main: "#ffc107",
        dark: "#c79100",
        contrastText: "#000000",
        text: "#000000"
      },
      text: {
        dark: "#263238",
        main: "#607d8b",
        light: "#eceff1"
      }
    },
    typography: {
      useNextVariants: true,
      darkText: "#263238",
      mainText: "#607d8b",
      lightText: "#eceff1"
    }
  })
};

//create app-context for non-user realted things eg theming  -> shift out to externatl context soon

const App = () => {
  
  const user = useLogin()

  // const snack = useLogin()

  //populate app Context with init, changabele data
  const [settings, setSettings] = useState({sortable:true, settingb:'somehting'})
  const [sortableExp, setSortableExp] = useState(false)

  // const snack = useSnack()
  const [snackOpen, setSnackOpen] = useState(true)
  const [snackInfo, setSnackInfo] = useState({type:'info', messageInfo:'some thing to inform'})

  return (
    <AppContext.Provider settings={settings}>
     <CssBaseline />
      <UserDataProvider user={user}>
      {/* <UserDataProvider > */}
        <MuiThemeProvider theme={setApp.appTheme}>
            {/* <CssBaseline /> */}
            <Router>
              <div>
                <GenSnack snackInfo={snackInfo} snackOpen={snackOpen} />
                <AppBarDrawer title={setApp.appName} isLoggedIn={user.isLoggedIn} settings={settings} setSettings={setSettings} >
                  {/* <CssBaseline /> */}
                  <div style={{ marginBottom: 56 }}>
                    <Route path="/" exact component={Login} />
                    <Route
                      exact
                      path="/home"
                      render={props => (
                        <HomeScreen {...props} someProp={true} />
                      )}
                    />
                    <Route path="/about" component={About} />
                    {/* <EventDataProvider eventId={user.isLoggedIn?user.userDetail.evntp: null} loggedIn={user.isLoggedIn}> */}
                    <EventDataProvider >
                      <Route path="/addEvent" component={EventBuilder}/>
                      <Route
                        path="/dashboard"
                        exact
                        component={WithAuth(Dashboard)}
                      />
                      <Route path="/moods" component={WithAuth(MoodBoard)} />
                      {/* <Route path="/tasks" component={WithAuth(Tasks)} /> */}
                      {/* <Route path="/calendar" component={WithAuth(Calendar)} /> */}
                      <Route path="/tasks" component={WithAuth(TasksReminders)} />
                      <Route path="/settings" component={WithAuth(Settings)} />
                    </EventDataProvider>
                  </div>
                </AppBarDrawer>
              </div>
            </Router>
          
        </MuiThemeProvider>
      </UserDataProvider>
    </AppContext.Provider>
  );
};
export default App;
