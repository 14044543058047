import React, { Component } from 'react';
import Layout from './Layout';
import SocialButtonList from '../components/SocialButtonList';
import SocialProfileList from '../components/SocialProfileList';
import Button from '@material-ui/core/Button'

import { auth } from '../firebase';

class Settings extends Component {

  static defaultProps = {
    providerData: []
  };
  state = {
    buttonList: {
      github: {
        visible: true,
        provider: () => {
          const provider = auth.githubOAuth();
          provider.addScope('user');
          return provider;
        }
      },
      google: {
        visible: false ,
        provider: () => auth.googleOAuth()
      },
      twitter: {
        visible: true,
        provider: () => auth.twitterOAuth()
      },
      facebook: {
        visible: true,
        provider: () => auth.facebookOAuth()
      }
    },
    providerData: this.props.providerData
  };

  componentDidMount() {
    this.updateProviders(this.state.providerData);
  }
  
  handleCurrentProviders = providerData => {
    this.updateProviders(providerData);
  };

  updateProviders = providerData => {
    let buttonList = { ...this.state.buttonList };

    providerData.forEach(provider => {
      const providerName = provider.providerId.split('.')[0];
      buttonList = this.updateButtonList(buttonList, providerName, false);
    });

    this.setState({ buttonList, providerData });
  };

  handleUnlinkedProvider = (providerName, providerData) => {
    if (providerData.length < 1) {
      auth
        .getAuth()
        .currentUser.delete()
        .then(() => console.log('User Deleted'))
        .catch(() => console.error('Error deleting user'));
    }

    let buttonList = { ...this.state.buttonList };
    buttonList = this.updateButtonList(buttonList, providerName, true);

    this.setState({ buttonList, providerData });
  };

  updateButtonList = (buttonList, providerName, visible) => ({
    ...buttonList,
    [providerName]: {
      ...buttonList[providerName],
      visible
    }
  });

  render() {
    return (

        <div>
          <h1>Settings</h1> 
          ----------------
      <Layout>
        <h1>Secure Area</h1>
        <SocialProfileList
          auth={auth.getAuth}
          providerData={this.state.providerData}
          unlinkedProvider={this.handleUnlinkedProvider}
        />
        <p style={{ textAlign: 'center' }}>
          <strong>Connect Other Social Accounts</strong>
        </p>
        <SocialButtonList
          buttonList={this.state.buttonList}
          auth={auth.getAuth}
          currentProviders={this.handleCurrentProviders}
        />
        <Button
          variant="contained"
          onClick={() => auth.getAuth().signOut()}
        >
          Logout
        </Button>
      </Layout>
      </div>
    );
  }
}

export default Settings;