import React, { useEffect, useContext, useState } from "react";

import { Link } from "react-router-dom";

import { AppContext } from "../containers/App";

import UserDataProvider from "../contextProviders/UserData";
import EventDataProvider from "../contextProviders/UserData";

import { useSignOut } from "../hooks/hooks";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";
import Input from "@material-ui/core/Input";

import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";

import { withStyles } from "@material-ui/core/styles";

import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import MenuIcon from "@material-ui/icons/Menu";
import EventIcon from "@material-ui/icons/Event";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import HomeIcon from "@material-ui/icons/Home";
import TasksIcon from "@material-ui/icons/CheckCircle";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import MoodsIcon from "@material-ui/icons/Favorite";
import SettingsIcon from "@material-ui/icons/Settings";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import HelpIcon from "@material-ui/icons/HelpOutline";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import { useUserFns } from "../hooks/hooks";

const drawerWidth = 320;

const styles = theme => ({
  root: {
    display: "flex"
  },

  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 2,

    top: "auto",
    bottom: 0
    // marginLeft: drawerWidth,
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    // },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },

  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.primary.main
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 0px",
    ...theme.mixins.toolbar,
    justifyContent: "space-around",
    backgroundColor: theme.palette.primary.main
  },

  drawerPaper: {
    width: drawerWidth
    // backgroundColor: theme.palette.primary.light,
  },

  content: {
    flexGrow: 1
    // alignText:'center'
  },

  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  eventSelect: {
    // backgroundColor: theme.palette.primary.light,
  },
  drawerBottomIn: {
    position: "fixed",
    bottom: "0px",
    // right:'0px',
    backgroundColor: theme.palette.primary.light
  },
  drawerBottomOut: {
    position: "fixed",
    bottom: "0px",
    // right:'0px',
    backgroundColor: theme.palette.secondary.main
  }
});

const {
 changeCurrentEvent
} = useUserFns();



const ResponsiveDrawer = props => {

  const user = useContext(UserDataProvider.context);
  const event = useContext(UserDataProvider.context);

  const { container } = props;
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  // function handleMenu(event) {
  //   setAnchorEl(event.currentTarget);
  // }

  // function handleClose() {
  //   setAnchorEl(null);
  // }

  const InnerDrawer = props => {

    const [currentEvent, setCurrentEvent] = useState("");


    useEffect(() => {

      let c_event = user.userEvents.find(evt => {
        return evt.id === user.userData.evnt_c;
      });
      setCurrentEvent(c_event);
    }, [event.currentEvent]);

    function handleEventChange(event) {
      // console.log(event.target.value);
      changeCurrentEvent(event.target.value)
      if (!props.fullScreen) {
        handleDrawerToggle();
      }
    }

    function handleAddEvent() {
      console.log("event adding handled");
    }

    return (
      <>
        <div className={classes.drawerHeader}>
          <Typography
            variant="h6"
            style={{ fontFamily: "Sacramento", color: "white" }}
            noWrap
          >
            {props.title}
          </Typography>
          <IconButton onClick={handleDrawerToggle}>
            <ChevronLeft style={{ color: "white" }} />
          </IconButton>
        </div>
        <List>
          <>
            {user.userEvents.length === 0 ? (

              <ListItem disabled={!props.isLoggedIn}>
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>

                <ListItemText>
                <Link to={'/addEvent'}  onClick={()=>handleDrawerToggle()} style={{ textDecoration: "none" }}>
                  <Typography variant={'body2'}>Add a new event</Typography>
                </Link>
                </ListItemText>
                <Link to={'/addEvent'}  onClick={()=>handleDrawerToggle()} style={{ textDecoration: "none" }}>
                <AddCircleIcon size="small" color="secondary" />
                </Link>
              </ListItem>
              
            ) : (
              <ListItem disabled={!props.isLoggedIn}>
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText>
                  {/* <form className={classes.eventSelect}> */}
                  <FormControl className={classes.formControl}>
                    <Select
                      value={"curr_evnt"}
                      onChange={handleEventChange}
                      // input={ <Input value={"first"} name={"first"} id="event-helper" />}
                    >
                      <MenuItem value="curr_evnt" disabled>
                        {currentEvent? currentEvent.name : null }
                      </MenuItem>
                      {user.userEvents.map((evnt, idx) =>
                        evnt.id !== currentEvent.id ? (
                          <MenuItem value={evnt.id} key={evnt.id}>
                            {evnt.name}
                          </MenuItem>
                        ) : null
                      )}
                      <Link to={'/addEvent'} style={{ textDecoration: "none" }}>
                      <MenuItem value="add_new">
                        <Typography>Add new event ...</Typography>
                      </MenuItem>
                      </Link>
                    </Select>
                  </FormControl>
                  {/* </form> */}
                </ListItemText>
              </ListItem>
            )}
            <Divider />
          </>

          {[
            {
              text: "Event Dashboard",
              value: "dashboard",
              icon: <DashboardIcon />
            },
            {
              text: "Tasks and Reminders",
              value: "tasks",
              icon: <TasksIcon />
            },
            // { text: "Calendar", value: "calendar", icon: <CalendarIcon /> },
            { text: "Moodboard", value: "moods", icon: <MoodsIcon /> },
            {
              text: "Event Info",
              value: "settings",
              icon: <SettingsIcon />
            }
          ].map((item, index) => (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={`/${item.value}`}
              onClick={!props.fullScreen ? handleDrawerToggle : null}
              disabled={!props.isLoggedIn}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>

        <Divider />

        <List>
          {[
            {
              text: "Account Settings",
              value: "settings",
              icon: <SettingsIcon />
            },
            { text: `${props.title} Home`, value: "home", icon: <HomeIcon /> },
            { text: "About", value: "about", icon: <HelpIcon /> }
          ].map((item, index) => (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={`/${item.value}`}
              onClick={!props.fullScreen ? handleDrawerToggle : null}
            >
              {props.isLoggedIn ? (
                <ListItemText secondary={item.text} />
              ) : (
                <ListItemText primary={item.text} />
              )}
            </ListItem>
          ))}

          <ListItem
            button
            key={"login"}
            component={Link}
            to={`/`}
            onClick={!props.fullScreen ? handleDrawerToggle : null}
            className={
              props.isLoggedIn
                ? classes.drawerBottomIn
                : classes.drawerBottomOut
            }
          >
            <ListItemText secondary={props.isLoggedIn ? "Signout" : "Signin"} />
            <ListItemIcon>
              <PowerIcon size={"10px"} />
            </ListItemIcon>
          </ListItem>
          {/* <Switch
              checked={props.settings.sortable}
              onChange={() => props.setSettings({...props.settings, sortable: !props.settings.sortable})}
              value="checkedA"
            /> */}
        </List>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="js">
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <InnerDrawer {...props} fullScreen={false} />
          </Drawer>
        </Hidden>

        <Hidden smDown implementation="js">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            <InnerDrawer {...props} fullScreen={true} />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {/* <Grid container>
            <Grid item xs={12}> */}
        {/* <div className={classes.toolbar}> Something here</div> */}
        <div>{props.children}</div>
        {/* </Grid>
        </Grid> */}
      </main>
    </div>
  );
};

export default withStyles(styles)(ResponsiveDrawer);
