import React, {useState} from "react";
import { withStyles } from "@material-ui/core/styles";

import Loading from './Loading';

import {
  List,
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Badge,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";

import CancelIcon from "@material-ui/icons/IndeterminateCheckBox";
import AddIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import TaskListItem from "./TaskListItem";

const styles = theme => ({
  listHeader: {
    display: "flex"
    //padding: theme.spacing.unit
    //backgroundColor: theme.palette.primary.light
  },
  listText: {
    color: theme.palette.primary.light,
    fontSize: "100%"
  },
  badge: {
    marginTop: -theme.spacing.unit * 4,
    margin: -theme.spacing.unit * 2
    // margin : -theme.spacing.unit
    //left: '50%'
  }
});

const ListHeader = props => {
  const { classes } = props;
  return (
    <Grid
      container
      className={classes.listHeader}
      direction="row"
      alignItems="flex-start"
      justify="space-between"
    >
      <Grid item xs={12}>
        <Typography className={classes.listText}>{props.title}</Typography>
      </Grid>
      <Grid item xs>
        <Badge
          badgeContent={props.items.filter(x => !x.chckd).length}
          color="primary"
          className={classes.badge}
        >
          <div />
        </Badge>
      </Grid>
    </Grid>
  );
};

const StyledListHeader = withStyles(styles)(ListHeader);

const AddingTodo = props => {

  const [value, setValue] = useState("");
  const handleSubmit = e => {
    e.preventDefault();
    props.onItemAdd(value);
    setValue("");
  };

  return (
    <div>
      <ListItem>
        <form onSubmit={e => handleSubmit(e)}>
          <IconButton aria-label="Save" onClick={props.onCancel}>
            <CancelIcon />
          </IconButton>
          <TextField
            label="Add a task"
            value={value}
            onChange={e => setValue(e.target.value)}
            autoFocus
          />
          <ListItemSecondaryAction>
            <Button
              size="small"
              color="secondary"
              aria-label="Save"
              type="submit"
            >
              <AddIcon />
            </Button>
            {/* <IconButton aria-label="Save" type="submit">
              <AddCircleIcon />
            </IconButton> */}
          </ListItemSecondaryAction>
        </form>
      </ListItem>
    </div>
  );
};

function TaskList(props) {

  const { classes } = props;

  // const sorted = props.items.sort((x, y) => {
  //   return x.chckd - y.chckd;
  // });

  return (
    <div>
      <Paper style={{ margin: 16 }}>
        <StyledListHeader title={props.listTitle} items={props.items} />
        <List>
          {props.addTodo ? (
            <AddingTodo
              onItemAdd={props.onItemAdd}
              onCancel={() => props.toggleAdd()}
              type="submit"
            />
          ) : null}

          { props.loading ? (
            <Loading circle inComponent/>
          ) : 
          ( props.items.length > 0) ? (
            //sorted.map((todo, idx) => (
            props.items.map((todo, idx) => (
              <TaskListItem
                {...todo}
                key={`${todo.id}`}
                toggleImportant={() => props.toggleImportant(todo.id, todo.isNb)}
                divider={idx !== props.items.length - 1}
                onDeleteClick={() => props.onItemRemove(todo.id)}
                onCheckBoxToggle={() => props.onItemCheck(todo.id, todo.chckd)}
                addDeadline={(date) => props.addDeadline(todo.id, date)}
              />
            ))
          ) : (
            <ListItem>
              <ListItemText> No tasks were found!</ListItemText>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => props.toggleAdd()}
              >
                Add
              </Button>
            </ListItem>
          )}
        </List>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(TaskList);
