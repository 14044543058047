import firebase from "./firebase";

// const storage = firebase.storage();
const storageRef = firebase.storage().ref()

export const getFullImageUrl = (userId='nJIxwy8nlFYYfEX0xPwkhUjaT7w2', image='1.jpg') => {
    storageRef.child(`${userId}/${image}`).getDownloadURL().then(url=>console.log(url))
};

export const getThumbImageUrl = (userId='nJIxwy8nlFYYfEX0xPwkhUjaT7w2', image='1.jpg') => {
    storageRef.child(`${userId}/${image}`).getDownloadURL().then(url=>console.log(url))
};

export const getMetadata = (userId='nJIxwy8nlFYYfEX0xPwkhUjaT7w2', image='1.jpg') => {
    storageRef.child(`${userId}/${image}`).getMetadata().then(function(metadata) {
        console.log(metadata)
      }).catch(function(error) {
        // Uh-oh, an error occurred!
      });
}


