import React, {useContext} from "react";
import { withStyles } from "@material-ui/core/styles";

import * as dateFns from "date-fns";

import {
  List,
  Listheader,
  ListItem,
  Card,
  Grid,
  Typography,
  IconButton,
  Badge
} from "@material-ui/core";


import ForwardIcon from "@material-ui/icons/KeyboardArrowRight";
import BackIcon from "@material-ui/icons/KeyboardArrowLeft";

import CalendarCell from "../components/CalendarCell";

import EventDataProvider from "../contextProviders/EventData";

import { useCalendarFns } from "../hooks/hooks";

import { withRouter } from "react-router";

const styles = theme => ({
  header: {
    padding: theme.spacing.unit*3 ,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  calendar: {
    padding: theme.spacing.unit,
    backgroundColor: theme.palette.primary.light
  },
  calendarHead: {
    color: theme.palette.primary.contrastText
  },

  cell: {
    minHeight: 50,
    padding: theme.spacing.unit
  },

  nameCell: {
    padding: theme.spacing.unit,
    color: theme.palette.primary.light
  },

  cellContainer: {
    padding: theme.spacing.unit,
    display: "grid",
    gridTemplateColumns: "repeat(7, auto)"
  },

  badge: {
    padding: theme.spacing.unit,
    marginTop: "50%",
    marginRight: "90%"
  },

  infoSection: {
    marginTop: theme.spacing.unit,
    padding: theme.spacing.unit
    // backgroundColor: theme.palette.primary.light,
    //color: theme.palette.primary.light
  },

  root: {
    padding: theme.spacing.unit
    // flexGrow: 1
  }
});





function Calendar(props) {

  const { classes } = props;
  const event = useContext(EventDataProvider.context);
  console.log(event.reminders)
  
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const { addReminder, removeReminder, updateReminder, setReminderNotify } = useCalendarFns();

  const dayNameList = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

  let myStartDate = dateFns.subDays(
    dateFns.startOfMonth(selectedDate),
    dateFns.getISODay(dateFns.startOfMonth(selectedDate)) - 1
  )
  let myEndDate = dateFns.addDays(
    dateFns.endOfMonth(selectedDate),
    7 - dateFns.getISODay(dateFns.endOfMonth(selectedDate))
  )
  const daysList = dateFns.eachDayOfInterval({start: myStartDate, end: myEndDate });

  const monthAddSub = fn => {
    switch (fn) {
      case "add":
        setSelectedDate(dateFns.addMonths(selectedDate, 1));
        break;
      case "sub":
        setSelectedDate(dateFns.subMonths(selectedDate, 1));
        break;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <Card>
          <Grid
            container
            className={classes.header}
            direction="row"
            justify="center"
          >
            <Grid item xs align="left">
             <IconButton className={classes.calendarHead} onClick={() => monthAddSub("sub")}>
              <BackIcon  />
              </IconButton>
            </Grid>
            <Grid item xs align="center">
              <Typography variant={'h6'} className={classes.calendarHead} >
                {`${dateFns.format(selectedDate, "MMMM")} ${dateFns.format(
                  selectedDate,
                  "yyyy"
                )}`}
              </Typography>
            </Grid>

            <Grid item xs align="right">
            <IconButton className={classes.calendarHead} onClick={() => monthAddSub("add")}>
              <ForwardIcon  />
              </IconButton>
            </Grid>
          </Grid>

          <div className={classes.cellContainer}>
            {dayNameList.map(dn => (
              <div key={dn}>
                <Typography className={classes.nameCell} variant="caption">
                  <i>{dn}</i>
                </Typography>
              </div>
            ))}
            {daysList.map(d => {
              return (
                <div
                  className={classes.cell}
                  key={dateFns.format(d, "ddMMyyyy")}
                >
                  <CalendarCell
                    date={d}
                    sDate={selectedDate}
                    reminderList={event.reminders}
                    taskList={event.tasks.filter(t=>t.dline)}
                  />
                </div>
              );
            })}
          </div>
        </Card>
        <div>
          <Card className={classes.infoSection}>
            Information/stats about how many days left until the event etc.{" "}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(withRouter(Calendar));
