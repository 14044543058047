import React from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  Card,
  List,
  ListItem,
  CardContent,
  Typography,
  Link,
  CardActions,
  Button
} from "@material-ui/core";

const styles = theme => ({
  card: {
    minWidth: 200
  }
});

const DashReminderCard = ({ event, ...props }) => {
  const { classes } = props;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="subtitle1">Upcoming reminders</Typography>
        <List>
          {event.reminders
            .filter(rem => {
              return rem;
            })
            .map((rem, idx) => (
              <ListItem key={idx}>
                {" "}
                <Link to={"/calendar"} style={{ textDecoration: "none" }}>
                  {" "}
                  * {rem.txt}
                </Link>
              </ListItem>
            ))}
        </List>
      </CardContent>
      <CardActions>
        <Link to={"/calendar"} style={{ textDecoration: "none" }}>
          <Button size="small">Go to Calendar</Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(DashReminderCard);
