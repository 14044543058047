import React, { memo, useState,useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as dateFns from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";

import {
  List,
  ListItem,
  Checkbox,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  ListItemIcon,
  MenuItem,
  Menu
} from "@material-ui/core";

import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import EventIcon from "@material-ui/icons/Event";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.light
  },
  indicator: {
    position: "absolute",
    minHeight: 56,
    marginTop: theme.spacing.unit,
    width: theme.spacing.unit / 2,
    backgroundColor: theme.palette.secondary.main
  }
});

const TodoListItem = props => {
  const { classes } = props;
  const pickerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedDate, handleDateChange] = useState(new Date());

  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleDelete() {
    handleClose();
    props.onDeleteClick();
  }

  function handleAddDeadline (d) {
      // handleDateChange(d)
      // console.log(d)
      props.addDeadline(dateFns.getUnixTime(d));
      handleClose()
    }

    const openPicker = React.useCallback(
      e => {
        if (pickerRef.current) {
          pickerRef.current.open(e);
        }
      },
      [pickerRef.current]
    );

  function toggleImportant() {
    handleClose();
    props.toggleImportant();
  }

  return (
    <>
      <div className={props.isNb ? classes.indicator : null} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker value={new Date()} onChange={handleAddDeadline} ref={pickerRef} style={{display:'none'}} />
      </MuiPickersUtilsProvider>
      <ListItem divider={props.divider}>
        <Checkbox
          onClick={props.onCheckBoxToggle}
          checked={props.chckd}
          disableRipple
        />
        <ListItemText
          primary={props.txt} 
          secondary={
            props.dline
              ? `Deadline: ${dateFns.format(dateFns.fromUnixTime(props.dline), "dd MMMM yyyy")}`
              : null
          }
          style={
            props.chckd
              ? {
                  textDecorationLine: "line-through",
                  textDecorationStyle: "solid"
                }
              : null
          }
        />
        <ListItemSecondaryAction>
          <IconButton aria-label="Delete Todo" onClick={handleMenu}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={toggleImportant}>
              {props.isNb ? (
                <>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText> Mark as not important </ListItemText>
                </>
              ) : (
                <>
                  <ListItemIcon>
                    <StarBorderIcon />
                  </ListItemIcon>
                  <ListItemText> Mark as important </ListItemText>
                </>
              )}
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <DeleteOutlined />
              </ListItemIcon>
              <ListItemText> Delete task </ListItemText>
            </MenuItem>
            <MenuItem onClick={openPicker}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText> {props.dline === null ? "Set task deadline" : "Reschedule Deadline"} </ListItemText>
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default withStyles(styles)(TodoListItem);
