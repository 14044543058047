import firebase from "./firebase";
import { useContext } from "react";
import { EventContext } from "../containers/App";

export const db = firebase.firestore();

firebase
  .firestore()
  .enablePersistence()
  .catch(function(err) {
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
// Subsequent queries will use persistence, if it was enabled successfully

// export const getUser = userId => {
//   const docRef = db.collection("users").doc(userId);
//   let userData = {}

//   docRef
//     .get()
//     .then(function(doc) {
//       if (doc.exists) {
//         return doc.data()
//         // console.log("Document data:", doc.data());
//       } else {
//         // doc.data() will be undefined in this case
//         console.log("No such document!");
//       }
//     }).then((doc)=>{
//       db.collection("events").doc(doc.evntp).get().then(
//         (doc) => {console.log(doc.data())}
//       )
//     })
//     .catch(function(error) {
//       console.log("Error getting document:", error);
//     });

//   // docRef.onSnapshot(function(doc) {
//   //   console.log(doc.data());
//   //   return doc.data();
//   // })
// };

// export const getEvent = eventId => {
//   const docRef = db.collection("events").doc(eventId);
//   docRef.onSnapshot(function(doc) {
//     console.log(doc.data());
//     return doc.data();
//   });
// };

// export const getEventTasks = async eventId => {
//   const docRef = db
//     .collection("events")
//     .doc(eventId)
//     .collection("tsks");
//   docRef.onSnapshot(
//     {
//       includeMetadataChanges: true
//     },
//     function(querySnapshot) {
//       let tasks = [];
//       querySnapshot.forEach(function(doc) {
//         tasks.push({ id: doc.id, ...doc.data() });
//       });
//       console.log(`dbSide :| -> ${tasks}`);
//       return tasks;
//       // console.log(tasks);
//     }
//   );
// };

// export const getEventReminders = async eventId => {
//   let remArray = [];
//   const docRef = db
//     .collection("events")
//     .doc(eventId)
//     .collection("rems");
//   await docRef.get().then(function(querySnapshot) {
//     querySnapshot.forEach(function(doc) {
//       remArray.push({ ...doc.data(), id: doc.id });
//     });
//   });
//   return remArray;
// };

// export const getEventImageList = async eventId => {
//   let imgArray = [];
//   const docRef = db
//     .collection("events")
//     .doc(eventId)
//     .collection("imgs");
//   await docRef.get().then(function(querySnapshot) {
//     querySnapshot.forEach(function(doc) {
//       imgArray.push({ ...doc.data(), id: doc.id });
//     });
//   });
//   return imgArray;
// };

// export const getTaskArray = async eventId => {
//   let taskArray = [];
//   const docRef = db
//     .collection("events")
//     .doc(eventId)
//     .collection("tsks");
//   await docRef.get().then(function(querySnapshot) {
//     querySnapshot.forEach(function(doc) {
//       taskArray.push({ ...doc.data(), id: doc.id });
//     });
//   });
//   console.log(taskArray);
//   return taskArray;
// };

// export const listenTaskArray = eventId => {
//   let taskArray = [];
//   const docRef = db
//     .collection("events")
//     .doc(eventId)
//     .collection("tsks");
//   docRef.onSnapshot(
//     querySnapshot =>
//       querySnapshot.forEach(doc => {
//         taskArray.push({ ...doc.data(), id: doc.id });
//         // console.log(doc.data())
//       })
//   );
// };

export const updateCurrentEvent = (event, user) => {
  let docRef = db
  .collection("users")
  .doc(user)
docRef
  .update({evnt_c: event})
  .then(function() {
    console.log(`Current event has been successfully been updated! ${event}`);
  })
  .catch(function(error) {
    // The document probably doesn't exist.
    console.error("Error updating document: ", error);
  });
}

export const addEvent = (event, userId) => {
  let docRef = db
    .collection("events")
  docRef
    .add(event)
    .then(function(docRef) {
      console.log("Event Document written with ID: ", docRef.id);
      updateCurrentEvent(docRef.id, userId)
    })
    .catch(function(error) {
      console.error("Error adding Event document: ", error);
    });
};


export const addItem = (eventId, collection, item) => {
  let docRef = db
    .collection("events")
    .doc(eventId)
    .collection(collection);
  docRef
    .add(item)
    .then(function(docRef) {
      console.log("Document written with ID: ", docRef.id);
    })
    .catch(function(error) {
      console.error("Error adding document: ", error);
    });
};

export const setItem = (eventId, collection, newId, item) => {
  let docRef = db
    .collection("events")
    .doc(eventId)
    .collection(collection)
    .doc(newId);

  docRef
    // .add(item)
    .set(item)
    .then(function(docRef) {
      console.log(`Document written with ID: ${newId}`);
    })
    .catch(function(error) {
      console.error("Error adding document: ", error);
    });
};

export const updateItem = (eventId, collection, docId, itemFragment) => {
  let docRef = db
    .collection("events")
    .doc(eventId)
    .collection(collection)
    .doc(docId);

  docRef
    .update(itemFragment)
    .then(function() {
      console.log(`Document ${docId} has successfully been updated!`);
    })
    .catch(function(error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    });
};

export const deleteItem = (eventId, collection, docId) => {
  let docRef = db
    .collection("events")
    .doc(eventId)
    .collection(collection)
    .doc(docId);

  docRef
    .delete()
    .then(function(docRef) {
      console.log(`Document with ID ${docId} has been permanently deleted!`);
    })
    .catch(function(error) {
      console.error("Error deleting document: ", error);
    });
};

export const getDbId = (eventId, collection) => {
  let ref = db
    .collection("events")
    .doc(eventId)
    .collection(collection)
    .doc();
  console.log(ref);
  return ref.id;
};

const getImgs = eventId => {};
const getRems = eventId => {};

const userMsgs = userId => {};
