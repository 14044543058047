import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { withStyles, withTheme } from "@material-ui/core/styles";

import AppContext from './App'

import Moodbar from "../components/MoodBar";
import Mood from "../components/Mood";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import tileData from "../tileData";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import Toolbar from "@material-ui/core/Toolbar";
import { CssBaseline } from "@material-ui/core";

import Portal from "@material-ui/core/Portal";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/StarBorder";
import CloseIcon from "@material-ui/icons/Close";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import UploadIcon from "@material-ui/icons/CloudUpload";
import MoreIcon from "@material-ui/icons/MoreVert";

import { withRouter } from "react-router";

import Compressor from "compressorjs";

import Headroom from "react-headroom";
import Gallery from "react-photo-gallery";
import { SortableContainer, SortableElement,sortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";

const styles = theme => ({
  root: {
    // display: "flex",
    // flexWrap: "wrap",
    // justifyContent: "space-around",
    // overflow: "hidden",
    // backgroundColor: theme.palette.background.paper
    backgroundColor: theme.palette.primary.dark
  },

  gridList: {
    maxWidth: 640,
    background: theme.palette.primary.dark
    // height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: "translateZ(0)"
  },
  previewGridList: {
    // maxWidth: 640,
    background: theme.palette.primary.dark,
     padding: '8px',
    // height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: "translateZ(0)"
  },

  titleBarUpload: true
    ? {
        background: `linear-gradient(to bottom, ${
          theme.palette.secondary.dark
        } 0%,rgba(0,0,0,0) 100%)`
      }
    : {
        background:
          "linear-gradient(to bottom, rgba(204,0,0,1) 0%, " +
          "rgba(0,0,0,0) 100%)"
      },

  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },

  icon: {
    color: "white"
  },

  fab: {
    margin: theme.spacing.unit,
    position: "fixed",
    // bottom: 64,
    right: theme.spacing.unit,
    zIndex: theme.zIndex.drawer + 2,
    bottom: 32
  },
  headroom: {
    background: theme.palette.primary.main,
    boxShadow: "1px 1px 1px rgba(0,0,0,0.25)"
  },
  likeButton: {
    color: theme.palette.primary.contrastText
  }
});



const SortablePhoto = SortableElement(item => <Mood {...item} />);
const SortableGallery = SortableContainer(({ items }) => (
  <div>
  <CssBaseline/>
  <Gallery columns={2} direction={"column"} photos={items} renderImage={SortablePhoto} />
  </div>
));

function MoodsList(props) {

  const { classes } = props;
  const uploadInput = useRef();

  const [files, setFiles] = useState([]);
  const [ items , setItems] = useState(tileData)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log()
    let newItems = arrayMove( items , oldIndex , newIndex )
    setItems(newItems)
    // setItems(({items} ) => ({
    //   items: arrayMove( items , oldIndex , newIndex )
    // }));
  };  

  const compressFiles = async filesToCompress => {
    // let compressor = new Compressor
    filesToCompress.forEach(file => {

      console.log(file.size)
      if (file.size < 10000000) {
          try {
          new Compressor(file, {
            quality: 0.6,
            // maxWidth: 640,
            success(result) {
              console.log("compressed");
              setFiles([...files, result]);
            },
            error(err) {
              console.log(err.message);
            }
          });
        } catch (e) {
          console.log(e);
        }
      } else {console.log('file too big please make sure it is lsess than 10mb')}
    });
  };

  const handleFileSelect = event => {
    // FYI [...uploadInput.current.files] is changing a file list into an array
    let selectedFiles = [...uploadInput.current.files];
    compressFiles(selectedFiles);
    window.scrollTo(64, 0);
  };

  const handleUploadCancel = e => {
    // console.log(e)
    setFiles(
      files.filter((f, idx) => {
        return idx !== e;
      })
    );
  };

  return (
    <div>
      <Headroom
        // downTolerance ={10}
        // upTolerance ={10}
        onPin={() => console.log("pinned")}
        onUnpin={() => console.log("unpinned")}
        // wrapperStyle={{ marginBottom: rhythm(1) }}
        className={classes.headroom}
        style={{
          background: "#546e7a",
          boxShadow: "1px 1px 1px rgba(0,0,0,0.25)"
        }}
      >
        <Moodbar />
      </Headroom>

      <div className={classes.root}>
        <div>
          <input
            accept="image/*"
            id="myInput"
            type="file"
            ref={uploadInput}
            // multiple
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
          <Fab
            color="secondary"
            className={classes.fab}
            onClick={e => uploadInput.current.click()}
          >
            <AddAPhotoIcon />
          </Fab>
        </div>
        <Grid container direction="column" justify="center" alignItems="center">
          
          {files.length > 0 ? (
            <Grid item xs={12}>
            <Paper>
              <GridList
                cellHeight={128}
                spacing={8}
                cols={1}
                className={classes.previewGridList}
              >
                {files.map((img, idx) => (
                  <GridListTile
                    key={`${idx}`}
                    // cols={tile.featured ? 2 : 1}
                    // rows={tile.featured ? 2 : 1}
                  >
                    <img
                      src={URL.createObjectURL(img)}
                      alt={"Upload preview"}
                    />
                    <GridListTileBar
                      title={
                        <IconButton
                          className={classes.icon}
                          onClick={() => handleUploadCancel(idx)}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                      titlePosition="top"
                      actionIcon={
                        <IconButton className={classes.icon}>
                          <UploadIcon />
                        </IconButton>
                      }
                      actionPosition="right"
                      className={classes.titleBarUpload}
                    />
                  </GridListTile>
                ))}
              </GridList>
              </Paper>
            </Grid>
          ) : null}
        </Grid>

        {(AppContext.sortable) ? 
        ( <SortableGallery
        items={items}
        onSortEnd={onSortEnd}
        axis={"xy"}
        pressDelay={200}
        disableAutoscroll={true}
      /> )
      : 
      ( <Gallery
          photos={tileData}
          columns={2}
          direction={"column"}
          // onClick={selectPhoto}
          renderImage={Mood}
      /> )}

      </div>
    </div>
  );
}

MoodsList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(withRouter(MoodsList));

{
  /* <Grid item> <GridList  cellHeight={160} spacing={0} cols={3} className={classes.gridList}> 
                  {tileData.map(tile => (
                    <GridListTile
                      key={`${tile.title}-${tile.author}`}
                      cols={tile.featured ? 2 : 1}
                      rows={tile.featured ? 2 : 1}
                    >
                      <img src={tile.src} alt={tile.title} />
                      <GridListTileBar
                        title={
                          ""
                          // <IconButton className={classes.icon}>
                          //   <FavoriteBorderIcon />
                          // </IconButton>
                        }
                        titlePosition="top"
                        actionIcon={
                          <Checkbox
                            className={classes.likeButton}
                            icon={<FavoriteBorderIcon fontSize="small" />}
                            checkedIcon={<FavoriteIcon />}
                            onChange={() => console.log("liked")}
                            value="explore"
                          />
                          // <IconButton className={classes.icon}>
                          //   <FavoriteBorderIcon />
                          // </IconButton>
                        }
                        actionPosition="right"
                        className={classes.titleBar}
                      />
                    </GridListTile>
                  ))}
                </GridList> </Grid> */
}
