import React, { useState, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Input from '@material-ui/core/Input'

import * as dateFns from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";

import { useEventFns } from "../hooks/hooks";

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
    flexGrow: 1
  },

  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2
  },
  resetContainer: {
    padding: theme.spacing.unit * 3
  }
});

const {
  addEvent
} = useEventFns();


function EventStepper(props) {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [date, setDate] = useState(new Date());
  const [dateType, setDateType] = useState(null);
  // const [anchorEl, setAnchorEl] = useState(false);

  const monthPicker = useRef(null);
  const dayPicker = useRef(null);

  // const open = Boolean(anchorEl);

  const { classes } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  // const steps = getSteps();

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }
  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }
  function handleReset() {
    setActiveStep(0);
  }

  // function handleRadioChange(event) {
  //   setDateType(event.target.value);
  // }

  const handleSubmit = e => {
    e.preventDefault();
    console.log();
    handleNext();
  };

  const handleFinalSubmit = e => {
    e.preventDefault();
    addEvent(name, date, dateType, desc)
    // console.log(event.target.value);
    console.log(e);
    handleNext();
  };

  // const handleNameChange = event => {
  //   event.preventDefault();
  //   setName(event.target.value);
  // };

  //  function handleCalClose() {
  //   // setAnchorEl(null);
  //  }

  const handleDateChange = d => {
    setDate(d); //dateFns.getUnixTime(d));
    // handleCalClose();
  };

  const openMonthPicker = React.useCallback(
    e => {
      if (monthPicker.current) {
        monthPicker.current.open(e);
      }
    },
    [monthPicker.current]
  );

  const openDayPicker = React.useCallback(
    e => {
      if (dayPicker.current) {
        dayPicker.current.open(e);
      }
    },
    [dayPicker.current]
  );

  // function getStepContent(step) {
  //     switch (step) {
  //       case 0:
  //         return <Step1 />;
  //       case 1:
  //         return <Step2 />;
  //       case 2:
  //         return <Step3 />;
  //       default:
  //         return "Unknown step";
  //     }
  //   }

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          value={date}
          onChange={handleDateChange}
          ref={dayPicker}
          style={{ display: "none" }}
        />
        <DatePicker
          value={date}
          onChange={handleDateChange}
          ref={monthPicker}
          style={{ display: "none" }}
          openTo="year"
          views={["year", "month"]}
        />
      </MuiPickersUtilsProvider>
      <Card>
      
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step key={1}>
              <StepLabel>Choose an event name</StepLabel>
              <StepContent>
              <form onSubmit={handleSubmit} className={classes.root}>
                <TextField
                  id="name-input"
                  label="Event name"
                  value={name}
                  helperText="eg. My Lisbon Wedding"
                  margin="normal"
                  fullWidth
                  required
                  onChange={e=>setName(e.target.value)}
                />
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      onClick={() => props.history.goBack()}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={handleNext}
                      className={classes.button}
                      type='submit'
                    >
                      {activeStep === 2 ? "Finish" : "Next"}
                    </Button>
                    </div>
                  </div>
                </form>
                
              </StepContent>
            </Step>
 
            <Step key={2}>
              <StepLabel>Event date</StepLabel>
              <StepContent>
              <form onSubmit={handleSubmit} className={classes.root}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="EventDate"
                    name="eventDate"
                    // className={classes.group}
                    value={dateType}
                    onChange={(e)=>setDateType(e.target.value)}
                    required
                  >
                    <FormControlLabel
                      onClick={() => openDayPicker()}
                      value="exact"
                      control={<Radio />}
                      label={
                        !dateType || dateType !== "exact" ? (
                          "I know the exact event date"
                        ) : (
                          <p>{dateFns.format(date, "eeee dd MMMM yyyy")}</p>
                        )
                      }
                    />
                    <FormControlLabel
                      onClick={() => openMonthPicker()}
                      value="estimate"
                      control={<Radio />}
                      label={
                        !dateType || dateType !== "estimate" ? (
                          "I only have a rough idea of when I want the event"
                        ) : (
                          <p>{dateFns.format(date, " MMMM yyyy")}</p>
                        )
                      }
                    />
                    <FormControlLabel
                      value="unknown"
                      control={<Radio />}
                      label="I have no date in mind"
                    />
                    {/* <FormControlLabel
                    value="disabled"
                    disabled
                    control={<Radio />}
                    label="(Disabled option)"
                /> */}
                  </RadioGroup>
                </FormControl>

                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={handleNext}
                      className={classes.button}
                      type='submit'
                    >
                      {activeStep === 2 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
                </form>
              </StepContent>
            </Step>
            
            <Step key={3}>
              <StepLabel> Finish up</StepLabel>
              <StepContent>

              <form onSubmit={handleFinalSubmit} className={classes.root}>

                <TextField
                  id="sdesc-input"
                  // label="Event description"
                  value={desc}
                  helperText="Maybe add a short event description?" 
                  margin="normal"
                  multiline
                  rowsMax={4}
                  fullWidth
                  onChange={e=>setDesc(e.target.value)}
                />

                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={handleNext}
                      className={classes.button}
                      type="submit"
                    >
                      {activeStep === 2 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
                </form>
              </StepContent>
            </Step>
          </Stepper>

        {activeStep === 3 && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>Done - you&apos;ve created up your event!</Typography>
            <Button onClick={()=>props.history.push('/dashboard')} className={classes.button}>
              Go to event dashboard
            </Button>
          </Paper>
        )}
      </Card>
    </div>
  );
}

export default withStyles(styles)(EventStepper);
