import React, {useState} from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import * as dateFns from "date-fns";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid"
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import Slide from "@material-ui/core/Slide";
import Grow from "@material-ui/core/Grow"
import Zoom from '@material-ui/core/Zoom';
import TextField from "@material-ui/core/TextField"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Fab from '@material-ui/core/Fab'
import CancelIcon from "@material-ui/icons/IndeterminateCheckBox";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },

   fab: {
    margin: theme.spacing.unit,
    position: 'fixed',
    // bottom: 64,
    right: theme.spacing.unit,
    zIndex: theme.zIndex.drawer + 2,
     bottom: 32,
   },
   
   noReminders: {
    // margin: theme.spacing.unit,
    // padding: theme.spacing.unit*2,
    // position: 'fixed',
    marginTop: 56,
    // left: "10%",
   },
})

function Transition(props) {
  //return <Slide direction="up" {...props} />;
  // return <Grow in={true} {...props} />;
  // return <Zoom in={true} {...props} style={{ transitionDelay:'250ms' }}/>;
  return <Zoom in={true} {...props}/>;
}

const AddingReminder = props => {

  const [value, setValue] = useState("");
  const handleSubmit = e => {
    e.preventDefault();
    props.onItemAdd(value);
    setValue("");
  };

  return (
    <div>
      <ListItem>
        <form onSubmit={e => handleSubmit(e)}>
          <IconButton aria-label="Save" onClick={props.onCancel}>
            <CancelIcon />
          </IconButton>
          <TextField
            label="Add a new Reminder"
            value={value}
            onChange={e => setValue(e.target.value)}
            autoFocus
          />
          <ListItemSecondaryAction>
            <Button
              size="small"
              color="secondary"
              aria-label="Save"
              type="submit"
            >
              <AddCircleIcon />
            </Button>
            {/* <IconButton aria-label="Save" type="submit">
              <AddCircleIcon />
            </IconButton> */}
          </ListItemSecondaryAction>
        </form>
      </ListItem>
    </div>
  );
};


function CalendarDay(props) {
  const { classes } = props;
  // const [open, setOpen] = React.useState(props.open);
  const reminders = props.reminders

  return (
      <Dialog
        fullScreen
        open={props.open}
        onClose={() => props.handleClose()}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body2" color="inherit" className={classes.flex}>
              {dateFns.format(props.date, 'EEEE d MMMM yyyy')} 
            </Typography>
          </Toolbar>
        </AppBar>
        <List>  
          <AddingReminder/> 

        {reminders.map( r => <ListItem key={r._id} >{r.txt}</ListItem> )}
 
        </List> 
        <div className={classes.noReminders}>
        <Grid container spacing={24} direction="column" alignItems="center" >
            <Grid item xs> <ErrorIcon fontSize="large" color="secondary"/> </Grid>
            <Grid item xs={6}> <Typography variant="h6"> No reminders were found for {dateFns.format(props.date, 'EEEE (do MMMM)')} </Typography> </Grid>
            <Grid item xs><Button variant="outlined" color="secondary"  >Add a reminder </Button></Grid>
          </Grid>
          </div>
        
        <Fab color='secondary' className={classes.fab}> <AddIcon/></Fab>
      </Dialog>
  );
}

export default withStyles(styles)(CalendarDay);
