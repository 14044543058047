import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Loading from "../components/Loading";
import { differenceInCalendarDays, fromUnixTime } from "date-fns";

import { withRouter } from "react-router";

import DashCard from "../components/DashCard";
import DashReminderCard from "../components/DashReminderCard";
import DashNoEvent from "../components/DashNoEvent";


import UserDataProvider from "../contextProviders/UserData";
import EventDataProvider from "../contextProviders/EventData";
import { List, ListItem, Slide } from "@material-ui/core";

import CheckBoxBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useTaskFns } from "../hooks/hooks";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const styles = theme => ({
  card: {
    minWidth: 200
  },
  primaryCard: {
    minWidth: 200,
    backgroundColor: theme.palette.primary.light
  },
  secondaryCard: {
    minWidth: 200,
    backgroundColor: theme.palette.secondary.main
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  root: {
    padding: theme.spacing.unit,
    flexGrow: 1
  },
  borderDecoration: {
    margins: theme.spacing.unit,
    // position:'fixed',
    height: "auto",
    width: "100%"
  },
  countTitle: {
    margin: 40
  }
});

const {
  addTask,
  checkTask,
  removeTask,
  markImportantTask,
  addDeadline
} = useTaskFns();

function Dashboard(props) {
  const { classes } = props;

  const user = useContext(UserDataProvider.context);
  const event = useContext(EventDataProvider.context);

  return (
    <>
      {!event.currentEvent ? (
        user.userEvents.length !== 0 ? (
          <Loading />
        ) : (
          <DashNoEvent/>
        )
      ) : (
        <div className={classes.root}>
          <Grid
            container
            spacing={8}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs>
              <DashCard event={event} />
            </Grid>

            <Grid item xs>
              <Card className={classes.secondaryCard}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Important Tasks
                  </Typography>

                  <List>
                    {event.tasks
                      .filter(task => {
                        return task.isNb && !task.chckd;
                      })
                      .map((task, idx) => (
                        <ListItem key={idx}>
                          <CheckBoxBlankIcon
                            size={"small"}
                            style={{ marginRight: 8 }}
                            onClick={() =>
                              checkTask(
                                task.id,
                                task.chckd,
                                event.currentEvent.id
                              )
                            }
                          />
                          {task.txt}
                        </ListItem>
                      ))}
                  </List>
                </CardContent>
                <CardActions>
                  <Link to={"/tasks"} style={{ textDecoration: "none" }}>
                    <Button size="small">Go to Tasks</Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs>
              <DashReminderCard event={event} />
            </Grid>

            <Grid item xs>
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h6" component="h6">
                    Messages
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Get in touch</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default withStyles(styles)(withRouter(Dashboard));
