import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { differenceInCalendarDays, fromUnixTime } from "date-fns";


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import EventIcon from "@material-ui/icons/Event";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import HomeIcon from "@material-ui/icons/Home";
import TasksIcon from "@material-ui/icons/CheckCircle";

import Fab from '@material-ui/core/Fab';

const styles = (theme) => ({
  card: {
    maxWidth: 400,
    minWidth: 300
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
   
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.duration.shortest ,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    // backgroundColor: red[500],
  },
});

const DashCard = ({event, ...props}) => {
  const {classes} = props;
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="logo" alt="Fern Events logo" src= "./images/fe_icon.png" className={classes.avatar}>
            FE
          </Avatar>
        }
        action={
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        }
        
        title={event.currentEvent.name}
        subheader={event.currentEvent.date.setDate}
      />
      <CardMedia
        className={classes.media}
        image='https://picsum.photos/640/300'
        title="Paella dish"
      />
      <CardContent>

      <Typography variant="h3" align="center">
                    {differenceInCalendarDays(
                      fromUnixTime(event.currentEvent.date.seconds),
                      new Date()
                    )}
      </Typography>
      <Typography color="textSecondary" align="center">
                    days to go!
        </Typography>

        {/* <Fab variant="extended" aria-label="Tasks" className={classes.fab}>
        <TasksIcon  size={'small'} color={'secondary'}/>
        Tasks
      </Fab>
      <Fab variant="extended" aria-label="Calendar" className={classes.fab}>
      <EventIcon  size={'small'} color={'secondary'}/>
        Calendar
      </Fab>
      <Fab variant="extended" aria-label="Moods" className={classes.fab}>
      <FavoriteIcon  size={'small'} color={'secondary'}/>
        Moods
      </Fab> */}

      </CardContent>
      <CardActions className={classes.actions} disableActionSpacing>
      
      <IconButton aria-label="Share">
          <TasksIcon />
        </IconButton>        
        <IconButton aria-label="Share">
          <EventIcon />
        </IconButton>
        <IconButton aria-label="Add to favorites">
          <FavoriteIcon/>
        </IconButton>
        <IconButton
          className={classnames(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Show more"
        >
          <ExpandMoreIcon />
        </IconButton>
        
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10
            minutes.
          </Typography>
          <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
            heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
            browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
            and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
            pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography>
          <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
            without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
            medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
            again without stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don’t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default withStyles(styles)(DashCard);
