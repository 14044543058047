import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';


import MenuIcon from '@material-ui/icons/Menu';
import TaskIcon from '@material-ui/icons/CheckCircle';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HomeIcon from '@material-ui/icons/Home';
import CalendarIcon from '@material-ui/icons/CalendarToday';

import { Link } from 'react-router-dom'
import AppBarDrawer from './AppBarDrawer';

const styles = theme => ({
  root: {
    width: '100%',
    height: 56,
    position: 'fixed',
    bottom: 0,
    // backgroundColor: theme.palette.primary.light
  },
})

function AppBottomNavigation(props) {
  const {classes} = props; 
  const [value, setValue] = React.useState(props.location.pathname);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  function handleChange(event, newValue) {
    setValue(newValue);
    // console.log(props.location.pathname)
  }

  return (
    <BottomNavigation value={props.location.pathname} onChange={handleChange} className={classes.root} >
      <BottomNavigationAction component={Link} to="/" label="Home" value="/" icon={<HomeIcon />}/>
      <BottomNavigationAction component={Link} to="/tasks" label="Tasks" value="/tasks" icon={<TaskIcon />} />
      <BottomNavigationAction component={Link} to="/calendar" label="Calendar" value="/calendar" icon={<CalendarIcon/>} />
      <BottomNavigationAction component={Link} to="/moods" label="Moods" value="/moods" icon={<FavoriteIcon/>} />
      {/* <BottomNavigationAction  className={classes.menuButton} onClick={handleDrawerToggle} aria-label="Open drawer" label="Menu" icon={<MenuIcon/>} /> */}
    </BottomNavigation>

  );
}

export default withRouter(withStyles(styles)(AppBottomNavigation))
