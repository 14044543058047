import React , {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import { AppContext } from "../containers/App";

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
});

const GenSnackbar = ({type, messageInfo = {'key': new Date().getTime(), 'message': "hardcoded message"} , open, setOpen, ...props }) => {

  // const [isOpen, setIsOpen] = useState({open})
  
  // const [messageInfo, setMessageInfo] = useState({messageInfo})

   const queue = [];
  // state = {
  //   open: false,
  //   messageInfo: {},
  // };

  const handleClick = message => () => {
    this.queue.push({
      message,
      key: new Date().getTime(),
    });
    if (open) {
      // immediately begin dismissing current message
      // to start showing new one

      // this.setState({ open: false });
      setOpen(false)

    } else {
      this.processQueue();
    }
  };

  const processQueue = () => {
    if (this.queue.length > 0) {
      this.setState({
        messageInfo: this.queue.shift(),
        open: true,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
    // this.setState({ open: false });
  };

  const handleExited = () => {
    this.processQueue();
  };

    const { classes } = props;
    // const { messageInfo } = this.state;

    return (
      <div>
        <Snackbar
          key={messageInfo.key}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          onExited={handleExited}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{messageInfo.message}</span>}
          action={[
            <Button key="undo" color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }

GenSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GenSnackbar);