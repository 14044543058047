const tileData = [
    {
      src: 'https://picsum.photos/200/300',
      title: 'Breakfast',
      author: 'jill111',
      cols: 2,
      featured: true,
      height: 300,
      width: 200
    },
    {
      src: 'https://picsum.photos/640/300',
      title: 'Tasty burger',
      author: 'director90',
      height: 300,
      width: 640
    },
    {
      src: 'https://picsum.photos/250/500',
      title: 'Camera',
      author: 'Danson67',
      height: 500,
      width: 250
    },
    {
      src: 'https://picsum.photos/640/640',
      title: 'Taewburger',
      author: 'diwer0',
      height: 640,
      width: 640
    },
    {
      src: 'https://picsum.photos/640/320',
      title: 'Camersdfa',
      author: 'Dansdf',
      height: 320,
      width: 640
    },
    {
      src: 'https://picsum.photos/300/300',
      title: 'Morning',
      author: 'fancycrave1',
      featured: true,
      height: 300,
      width: 300
    },
    {
      src: 'https://picsum.photos/640/300',
      title: 'Hats',
      author: 'Hans',
      height: 300,
      width: 640
    },
    {
      src: 'https://picsum.photos/200/300',
      title: 'Honey',
      author: 'fancycravel',
      height: 300,
      width: 200
    },
    {
      src: 'https://picsum.photos/640/300',
      title: 'Vegetables',
      author: 'jill111',
      cols: 2,
      height: 300,
      width: 640
    },
    {
      src: 'https://picsum.photos/100/100',
      title: 'Water plant',
      author: 'BkrmadtyaKarki',
      height: 100,
      width: 100
    },
    {
      src: 'https://picsum.photos/200/300',
      title: 'Mushrooms',
      author: 'PublicDomainPictures',
      height: 300,
      width: 200
    },
    {
      src: 'https://picsum.photos/640/320',
      title: 'Olive oil',
      author: 'congerdesign',
      height: 320,
      width: 640
    },
    {
      src: 'https://picsum.photos/600/300',
      title: 'Sea star',
      cols: 2,
      author: '821292',
      height: 300,
      width: 600
    },
    {
      src: 'https://picsum.photos/150/300',
      title: 'Bike',
      author: 'danfador',
      height: 300,
      width: 150
    },
  ];
  
  export default tileData;