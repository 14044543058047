import React, { memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as dateFns from "date-fns";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";

import CalendarDay from "../components/CalendarDay";
import { getThemeProps } from "@material-ui/styles";

const styles = theme => ({
  root: {},
  currentDate: {},
  extraDate: {
    color: theme.palette.primary.light
  },
  date: {},
  badge: {
    top: "100%"
  },
  secondaryBadge: {
    top: "0%",
    right: "25%"
  }
});

function CalendarCell({ reminderList, taskList, date, sDate, ...props }) {
  const { classes } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [openDate, setOpenDate] = React.useState(sDate);

  const filteredReminders = date =>
    reminderList.filter(d => dateFns.isSameDay(d.dte, date));

  const filteredTasks = date =>
    taskList.filter(d =>
      dateFns.isSameDay(dateFns.fromUnixTime(d.dline), date)
    );

  const openDialog = d => {
    setOpenDate(d);
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };

  const BadgeGenerator = props => {
    if (props.remList.length === 1) {
      return (
        <Badge
          variant="dot"
          className={classes.badge}
          badgeContent={props.remList.length}
          color={props.color}
        >
          {props.children}
        </Badge>
      );
    } else if (props.remList.length > 1) {
      return (
        <Badge
          className={classes.badge}
          badgeContent={props.remList.length}
          color={props.color}
        >
          {props.children}
        </Badge>
      );
    } else {
      return <div>{props.children}</div>;
    }
  };

  const cellTextDecorator = () => {
    return !dateFns.isSameMonth(date, sDate) ? (
      <Typography variant="caption" className={classes.extraDate}>
        {dateFns.format(date, "d")}
      </Typography>
    ) : !dateFns.isSameDay(new Date(), date) ? (
      <Typography variant="body2" className={classes.date}>
        {dateFns.format(date, "d")}
      </Typography>
    ) : (
      <Typography variant="body2" className={classes.currentDate}>
        <strong>{dateFns.format(date, "d")}</strong>
      </Typography>
    );
  };

  return (
    <div>
      <div>
        <CalendarDay
          open={dialogOpen}
          handleClose={closeDialog}
          date={openDate}
          reminders={filteredReminders(openDate)}
          tasks={filteredTasks(openDate)}
        />
      </div>
      <div>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          onClick={() => openDialog(date)}
        >
          <BadgeGenerator
            remList={filteredReminders(date)}
            taskList={filteredTasks(date)}
            color="secondary"
          >
            {cellTextDecorator()}
          </BadgeGenerator>
          {props.children}
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(CalendarCell);
