import React, { memo, useContext } from "react";

import { withStyles } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import TaskList from "../components/TaskList";

import { useTaskFns } from "../hooks/hooks";
import { withRouter } from "react-router";

import EventDataProvider from "../contextProviders/EventData";

const styles = theme => ({
  root: {
    // padding: theme.spacing.unit,
    // flexGrow: 1,
  },

  fab: {
    margin: theme.spacing.unit,
    position: "fixed",
    // bottom: 64,
    right: theme.spacing.unit,
    zIndex: theme.zIndex.drawer + 2,
    bottom: 32
  }
});

const TodoApp = (props) => {
  
  const { classes } = props;

  const event = useContext(EventDataProvider.context);

  // console.log(`Tasks => ${eventData.tasks}`)
  // let todos = eventData.tasks

  // const loading = event.eventLoading
  // if (!loading) { todos = event.eventTasks }

  const {
    addTask,
    checkTask,
    removeTask,
    markImportantTask,
    addDeadline
  } = useTaskFns();

  const [addTodoVisible, setAddTodoVisible] = React.useState(false);

  return (
    <div>
      <div className={classes.root}>
       <TaskList
          items={event.tasks}
          loading={event.isLoading}
          onItemCheck={(id,value) => checkTask(id,value,event.currentEvent.id)}
          onItemRemove={id => removeTask(id,event.currentEvent.id)}
          onItemAdd={val => addTask(val,event.currentEvent.id)}
          listTitle=""
          addTodo={addTodoVisible}
          toggleAdd={() => setAddTodoVisible(!addTodoVisible)}
          toggleImportant={(id,value) => markImportantTask(id, value,event.currentEvent.id)}
          addDeadline = {(id, value)=> addDeadline(id, value,event.currentEvent.id)}
        />

        {!addTodoVisible ? (
          <Fab
            color="secondary"
            aria-label="Add"
            className={classes.fab}
            onClick={() => setAddTodoVisible(!addTodoVisible)}
          >
            <AddIcon />
          </Fab>
        ) : (
          <Fab
            size="small"
            aria-label="Cancel"
            className={classes.fab}
            onClick={() => setAddTodoVisible(!addTodoVisible)}
          >
            <CloseIcon />
          </Fab>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(withRouter(TodoApp));
