import { useState, useEffect, useContext, createContext } from "react";

import * as dateFns from "date-fns";
import { db } from "../firebase";
import { auth } from "../firebase";

// import firebase from "../firebase/firebase";
// export const dbDirect = firebase.firestore();

export const useLogin = () => {
  const [userProvided, setUserProvided] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    auth.getAuth().onAuthStateChanged(user => {
      if (user) {
        setUserProvided(user);
        setIsLoggedIn(true);
        console.log(`Logged in as: ${user.uid} `);
      } else {
        setUserProvided({});
        setIsLoggedIn(false);
        console.info("Login required.");
      }
    });
    return function cleanup() {
      console.log(
        "unsubscribe from logged in things? if need be. maybe later?!!"
      );
    };
  }, []);
  return {
    userProvided,
    isLoggedIn
  };
};

export const useSignOut = () => {
  useEffect(() => {
    auth.getAuth().signOut();
  }, []);
};

export const useUnlinkProvider = provider => {
  useEffect(() => {
    auth.getAuth().signOut();
  }, []);
};

export const useUserFns = () => {
  return {
    changeCurrentEvent: (event) => {
      var user = auth.getAuth().currentUser;
      if (user) {      
          db.updateCurrentEvent(event, user.uid);
      } else {
        console.log('Not loggin in - Oddly, you need to be logged in to perform this function')
      }
    }
  }
}

export const useEventFns = () => {
  // const [value, setValue] = useState("");
  return {
    addEvent: (name, date, date_type, desc) => {
      var user = auth.getAuth().currentUser;
      if (user) {
        if (name !== "") { 
          let event = {name: name, date: date, date_type: date_type, desc:desc, creator: user.uid, users:[user.uid] };
          db.addEvent(event,user.uid);
        }
  // User is signed in.
      } else {
        console.log('not loggin in - oddly, you need to be logged in to perform this function')
      }
    },
    updateEvent: (eventId, value) => {
      db.updateEvent(eventId, value );
    },
  };
};


export const useMoodsFns = (initialValue = []) => {
  const [moodsList, setMoodsList] = useState();

  return {
    //basic actions
    addToEvent: img => {
      if (img !== "" && img) {
        // let task = { txt, chckd: false, isNb: false, dline: "" };
        // db.addItem("tsks", task)
      }
    },
    likeMood: idx => {},

    //advanced actions
    removeFromEvent: idx => {},
    searchMood: string => {},
    commentMood: id => {},
    //tag
    addMoodTag: id => {},
    removeMoodTag: (id, tag) => {},
    //flags
    markAsFavourite: id => {},
    markAsPrivate: id => {},
    markAsShareworthy: id => {},
    markAsGraphic: id => {},
    //moderation
    reportMood: id => {}
  };
};


export const useCalendarFns = () => {
  return {
    addReminder: (txt, date, notify, eventId) => {
      if (txt !== "") {
        let reminder = { txt, chckd: false, isNb: false, dte: date, ntfy: notify };
        db.addItem(eventId, "rems", reminder);
      }
    },
    updateReminder: (docId, value, eventId) => {
      db.updateItem(eventId, "rems", docId, value );
    },
    removeReminder: (docId, eventId ) => {
      db.deleteItem(eventId, "rems", docId);
    },
    setReminderNotify: (docId, notify, eventId) => {
      db.updateItem(eventId, "rems", docId, { ntfy: notify });
    },
  };
};

export const useTaskFns = () => {
  return {
    addTask: (txt, eventId) => {
      if (txt !== "") {
        // let docId = db.getDbId(eventId, "tsks");
        // db.setItem(eventId, "tsks", docId, task);
        let task = { txt, chckd: false, isNb: false, dline: null };
        db.addItem(eventId, "tsks", task);
      }
    },
    addDeadline: (docId, value, eventId) => {
      db.updateItem(eventId, "tsks", docId, { dline: value });
    },
    checkTask: (docId, value, eventId) => {
      db.updateItem(eventId, "tsks", docId, { chckd: !value });
    },
    removeTask: (docId, eventId ) => {
      db.deleteItem(eventId, "tsks", docId);
    },
    markImportantTask: (docId, value, eventId ) => {
      db.updateItem(eventId, "tsks", docId, { isNb: !value });
    }
  };
};

// export const useNotify = (open, type, message, action1, action2) => {
//   const [open, setOpen] = useState(false);
//   const [queue, setQueue] = useState([]);
//   setQueue(new Date());
//   return { open, type, message, queue };
// };

export const useInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value);
      }
    }
  };
};
