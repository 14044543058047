import React, { createContext, useState, useEffect } from "react";

import { auth } from "../firebase";
import firebase from "../firebase/firebase";
const db = firebase.firestore();

const context = createContext(null);

export default function UserDataProvider(props) {
  const [userData, setUserData] = useState({}); 
  const [isLoading, setIsLoading] = useState(true);
  const [userEvents, setUserEvents] = useState([]);
  const [isUserEventsLoading, setIsUserEventsLoading] = useState(true);

  // Do something after first app load event per session : 
  useEffect(() => {
    if (props.user.isLoggedIn) {
      // do some stuff to add image etc etc.
      console.log(props.user.userProvided.uid);
    }
  }, []);

  //Get the user's application data from our database on every isLoggedIn change
  useEffect(() => {
    if (props.user.isLoggedIn) {
      const docRef = db.collection("users").doc(props.user.userProvided.uid);
      docRef
        .get()
        .then(function(doc) {
          if (doc.exists) {
            console.log(doc.data())
            setUserData(doc.data())
            setIsLoading(false)
          } else {
            console.log("User has not registered!");
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error);
        });
    }
  }, [props.user.isLoggedIn]);

  // Get (and watch/subscribe to ) basic info for all of the events that the user is a member of!
  // todo:  on change only ge the latest item (not entire list again):
  useEffect(() => {
      if (props.user.isLoggedIn) {
      const docRef = db
        .collection("events")
        .where("users", "array-contains", props.user.userProvided.uid);
      docRef.onSnapshot(
        // {
        //   includeMetadataChanges: true
        // },
        function(querySnapshot) {
          let events = [];
          querySnapshot.forEach(function(doc) {
            events.push({ id: doc.id, ...doc.data() });
          });
          setUserEvents(events);
          setIsUserEventsLoading(false);
          console.log(events);
        },
        function(error) {
          //...handle error
        }
      );
    }
  }, [userData.evnts]);

  // Watch for changes on the user profile: 
  useEffect(() => {
      if (props.user.isLoggedIn) {
        const docRef = db.collection("users").doc(props.user.userProvided.uid);
        docRef.onSnapshot(function(doc) {
          setUserData(doc.data())
          // console.log("Current data: ", doc.data());
        })
        // docRef.onSnapshot(
        // {
        //   includeMetadataChanges: true
        // },
      //   function(error) {
      //     console.log("User update error");
      //   }
      // );
    }
  }, [userData.evnt_c]);


  let user = { userData, isLoading, userEvents, isUserEventsLoading };
  return <context.Provider value={user}>{props.children}</context.Provider>;
}

UserDataProvider.context = context;
